.access-picker-table .ant-table-row-level-0 td {
  font-weight: bold;
}

.access-picker-table .ant-table-row-level-1 td {
  background: #fafafa;
}

.access-picker-table .ant-table-row-level-1.ant-table-row-selected td {
  background: #c8e5fa;
}

.access-picker-table
  .ant-table-row-level-0
  td.ant-table-selection-column
  .ant-checkbox-checked
  span.ant-checkbox-inner {
  background-color: green;
  border-color: green;
}

.access-picker-table
  .ant-table-row-level-0
  td.ant-table-selection-column
  .ant-checkbox-indeterminate
  span.ant-checkbox-inner::after {
  background-color: green;
  border-color: green;
}
