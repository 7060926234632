.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 0px 24px 16px 0;
}

.logo img {
  height:50px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.layout-content{
  padding: 0 50px;
}

.site-layout-content {
  background: #fff;
  padding-bottom: 50px;
}

.site-layout-content .content-body {
  padding: 0 20px 0 20px;
}

.add-configuration {
  text-align: right;
  padding: 20px;
}

.actions button {
  margin: 3px;
}

.form-errors {
  margin-bottom: 10px;
}

.login {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  padding-top: 200px;
}

.navbar-menu {
  flex: 1;
}

.navbar-profile {
  color: white;
}

.navbar-profile a {
  color: white;
  font-weight: bold;
}

.navbar-profile span {
  margin-right: 0.5rem;
}

.header{
  display: flex;
}