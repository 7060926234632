.dataSource {
  position: relative;
}

.dataSource .query {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 10px;
  display: none;
  width: 35%;
  height: 25%;
}

.dataSource .alias {
  color: #0000EE
}

.dataSource:empty .query {
  display: none;
}

.dataSource:hover:not(:empty) .query {
  display: block;
}

.dataSource:hover .alias {
  color: darkblue;
  font-weight:bold;
 }
